import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { LanguageChooser } from "./LanguageChooser";
import "./Login.css";
import { useCookies } from "react-cookie";

const siteAddress: string = "https://mateo-justine.wedding";

export const Login: React.FC = () => {
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [, setCookie, removeCookie] = useCookies(["auth"]);
  const { t } = useTranslation();
  const onSubmit: React.FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();
    removeCookie("auth");
    setCookie("auth", password, {
      path: "",
      expires: new Date(new Date().getTime() + 7 * 24 * 60 * 60 * 1000),
      secure: true,
      domain: "mateo-justine.wedding",
      sameSite: "strict",
    });
    window.location.href = siteAddress;
  };
  return (
    <div className="container">
      <div className={"chooserContainer"}>
        <LanguageChooser />
      </div>
      <form className="login-form" onSubmit={onSubmit}>
        <label>{t("login.email")}</label>
        <input
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <label>{t("login.password")}</label>
        <input
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <input type="submit" value={t("login.confirmation")} />
      </form>
    </div>
  );
};
