import React from "react";
import i18n from "./i18n";
import Dropdown from "react-dropdown";
import 'react-dropdown/style.css';

export const LanguageChooser: React.FC = () => {
    const languageOptions = [
        {value: 'en', label: "🇬🇧 English"},
        {value: 'es', label: "🇲🇽 Español"},
        {value: 'fr', label: "🇫🇷 Français"},
    ]
    const language = i18n.language.toLowerCase().substring(0, 2)
    return <Dropdown options={languageOptions} value={language} onChange={option =>
        i18n.changeLanguage(option.value).catch(console.log)
    }/>
        ;
}
